import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const Check = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={4}
    stroke="currentColor"
    className="w-6 h-6 m-auto"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 12.75l6 6 9-13.5"
    />
  </svg>
);

const AllergenRow = ({
                       name,
                       allergens,
                       special,
                       sauces
                     }: {
  name: string;
  allergens: string[];
  sauces?: {
    title: string;
    allergens?: string[];
  }[];
  special: {
    title: string;
    allergens?: string[];
  }
}) => {
  return (
    <React.Fragment>
      <tr>
        <td
          className="text-lg sticky left-0 z-10 p-4 bg-gradient-to-r from-white to-white/80 border-b-2 border-yellow-100">
          {name.split("(")[0]}
        </td>
        {allAllergens.map((allergenColumn, i) => (
          <td
            className={`h-16 border-b-2 border-yellow-100 text-brown-500 ${i % 2 ? "bg-white" : "bg-yellow-100"}`}
          >
            {allergens && allergens.includes(allergenColumn) ? <Check/> : null}
          </td>
        ))}
      </tr>
      {special?.title && special?.allergens ?
        <tr>
          <td
            className="text-lg sticky left-0 z-10 p-4 bg-gradient-to-r from-white to-white/80 border-b-2 border-yellow-100">
            {name.split("(")[0] + " " + special.title}
          </td>
          {allAllergens.map((allergenColumn, i) => (
            <td
              className={`h-16 border-b-2 border-yellow-100 text-brown-500 ${i % 2 ? "bg-white" : "bg-yellow-100"}`}
            >
              {special.allergens && special.allergens.includes(allergenColumn) ? <Check/> : null}
            </td>
          ))}
        </tr> : null}
      {sauces ? sauces.map(({title, allergens}) => (
        <tr>
          <td
            className="text-lg sticky left-0 z-10 p-4 bg-gradient-to-r from-white to-white/80 border-b-2 border-yellow-100">
            {title}
          </td>
          {allAllergens.map((allergenColumn, i) => (
            <td
              className={`h-16 border-b-2 border-yellow-100 text-brown-500 ${i % 2 ? "bg-white" : "bg-yellow-100"}`}
            >
              {allergens && allergens.includes(allergenColumn) ? <Check/> : null}
            </td>
          ))}
        </tr>)) : null}
    </React.Fragment>
  );
};

const MenuAllergens = ({
                         title,
                         items,
                       }: {
  title: string;
  items: { name: string; allergens: string[]; enabled: boolean, special: any, sauces: any[] }[];
}) => {
  return (
    <table className="table-fixed relative p-4">
      <thead>
      <tr>
        <th
          className="sticky top-32 lg:top-20 left-0 z-20 w-96 text-lg font-semibold text-red-500 uppercase py-2 bg-white">
          {title}
        </th>
        {allAllergens.map((allergenName, i) => (
          <th
            className={`sticky top-32 lg:top-20 min-w-[4rem] uppercase whitespace-nowrap pb-2 ${
              i % 2 ? "bg-white" : "bg-gradient-to-t from-yellow-100 to-white"
            }`}
          >
              <span className="block -rotate-12 translate-y-2">
                {allergenName}
              </span>
          </th>
        ))}
      </tr>
      </thead>
      <tbody>{items.map(AllergenRow)}</tbody>
    </table>
  );
};

const allAllergens = [
  "dairy",
  "eggs",
  "gluten",
  "soy",
  "peanuts",
  "tree nuts",
  "celery",
  "mustard",
  "sesame seed",
  "sulphur",
  "lupins",
  "molluscs",
  "fish",
  "crustaceans"
];

const AllergensTable = () => {
  const {
    allMenuJson: {nodes},
  } = useStaticQuery(query);

  return nodes
    .filter(
      ({items}: { items: { enabled: boolean, allergens: string[] }[] }) =>
        !items.every(({allergens, enabled}) => (!allergens && enabled))
    ).map(MenuAllergens);
};

export default AllergensTable;

const query = graphql`
  query AllergensQuery {
    allMenuJson {
      nodes {
        title
        items {
          name
          enabled
          allergens
          sauces {
             title
             allergens
            }
          special {
            title
            allergens 
          }
        }
      }
    }
  }
`;
