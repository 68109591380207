import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import AllergensTable from "../components/allergens_table";
import Heading from "../components/heading";
import { PromoWebshop } from "../components/promo";
import fill from "../images/fill.png";
import illustration from "../images/0_disegno.png";
import SEO from "../seo";

const Allergens = () => {
  return (
    <Layout className="snap-proximity">
      <HeaderSpacer />
      <div className="sticky left-0">
        <Heading>Nutrition & Allergens</Heading>
        <p className="p-4 max-w-screen-lg">
          {/* prettier-ignore */}
          <Trans>Here at Panzerò we only cook with the freshest seasonal ingredients. Due to the handcrafted nature of our food, and our use of shared cooking and preparation areas, we cannot guarantee that any of our dishes are completely allergen-free. Products containing wheat, eggs, nuts, and dairy are all made in our kitchen</Trans>
        </p>
        <div
          className="block w-full h-64 relative"
          style={{
            background: `center center / auto 100% url(${fill}) repeat-x`,
          }}
        >
          <img className="h-full mx-auto" src={illustration} />
        </div>
      </div>
      <AllergensTable />
      <PromoWebshop className="sticky left-0" />
    </Layout>
  );
};

export default Allergens;

export const Head: HeadFC = () => <SEO title="Allergens" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
